import { createWebHistory, createRouter } from "vue-router";

import store from '@/state/store'
import userTokenCheck from "../state/api/userTokenCheck";
//import routes from './routes'

const adminKontrol = async(to,from,next)=>{
  const {tokenCheck} = userTokenCheck();
  const login = await tokenCheck();
  if(!login){
    next({name:'login'})
  }
  else{
    next()
  }
}


const router = createRouter({
  history: createWebHistory(),
  routes:[
    {
      path: "/",
      name: "default",
      meta: {
        authRequired: true,
      },
      component: () => import("./views/dashboards/default"),
    },
    {
      path: "/login",
      name: "login",
      component: () => import("./views/account/login"),
      meta: {
        beforeResolve(routeTo, routeFrom, next) {
          // If the user is already logged in
          let users = JSON.parse(localStorage.getItem('users'))
          
          if (!users) {
            // Redirect to the home page instead
            next({ name: "default" });
          } else {
            // Continue to the login page
            next();
          }
        },
      },
    },
    {
      path: "/register",
      name: "Register",
      component: () => import("./views/account/register"),
      meta: {
        beforeResolve(routeTo, routeFrom, next) {
          // If the user is already logged in
          if (store.getters["auth/loggedIn"]) {
            // Redirect to the home page instead
            next({ name: "default" });
          } else {
            // Continue to the login page
            next();
          }
        },
      },
    },
    {
      path: "/forgot-password",
      name: "Forgot password",
      component: () => import("./views/account/forgot-password"),
      meta: {
        beforeResolve(routeTo, routeFrom, next) {
          // If the user is already logged in
          if (store.getters["auth/loggedIn"]) {
            // Redirect to the home page instead
            next({ name: "default" });
          } else {
            // Continue to the login page
            next();
          }
        },
      },
    },
    {
      path: "/logout",
      name: "logout",
      component: () => import("./views/account/logout"),
      meta: {
        authRequired: true,
        beforeResolve(routeTo, routeFrom, next) {
          if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
            store.dispatch("auth/logOut");
          } else {
            store.dispatch("authfack/logout");
          }
          const authRequiredOnPreviousRoute = routeFrom.matched.some((route) =>
            route.push("/login")
          );
          // Navigate back to previous page, or home as a fallback
          next(
            authRequiredOnPreviousRoute ? { name: "default" } : { ...routeFrom }
          );
        },
      },
    },
    {
      path: "/404",
      name: "404",
      component: require("./views/utility/404").default,
    },
    // Redirect any unmatched routes to the 404 page. This may
    // require some server configuration to work in production:
    // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
    // {
    //   path: "*",
    //  redirect: "404",
    //  component: require("./views/utility/404").default,
    // },
    {
      path: "/user/user-list",
      name: "user-list",
      meta: { authRequired: true },
      component: () => import("./views/user/user-list"),
      beforeEnter:adminKontrol
    },
    {
      path: "/user/user-add",
      name: "user-add",
      meta: { authRequired: true },
      component: () => import("./views/user/user-add"),
      beforeEnter:adminKontrol
    },
    {
      path: "/user/user-detail/:id",
      name: "userDetail",
      meta: { authRequired: true },
      component: () => import("./views/user/user-detail"),
      beforeEnter:adminKontrol
    },
    {
      path: "/user/user-category-edit/:id",
      name: "userCategoryEdit",
      meta: { authRequired: true },
      component: () => import("./views/user/user-category-edit"),
      beforeEnter:adminKontrol
    },
    {
      path: "/user/manager-list",
      name: "manager-list",
      meta: { authRequired: true },
      component: () => import("./views/user/manager-list"),
      beforeEnter:adminKontrol
    },
    {
      path: "/user/profile/:id",
      name: "UserProfili",
      meta: { authRequired: true },
      component: () => import("./views/user/profile"),
      beforeEnter:adminKontrol
    },
    {
      path: "/user/manager-detail/:id",
      name: "Manager Detail",
      meta: { authRequired: true },
      component: () => import("./views/user/manager-detail"),
      beforeEnter:adminKontrol
    },
    {
      path: "/user/add-manager",
      name: "add-manager",
      meta: { authRequired: true },
      component: () => import("./views/user/add-manager"),
      beforeEnter:adminKontrol
    },
    {
      path: "/user/add-file-manager",
      name: "add-file-manager",
      meta: { authRequired: true },
      component: () => import("./views/user/add-file-manager"),
      beforeEnter:adminKontrol
    },
    {
      path: "/user/file-manager",
      name: "file-manager-list",
      meta: { authRequired: true },
      component: () => import("./views/user/file-manager"),
      beforeEnter:adminKontrol
    },
    {
      path: "/category/category-list",
      name: "category-list",
      meta: { authRequired: true },
      component: () => import("./views/user/category-list"),
      beforeEnter:adminKontrol
    },
    {
      path: "/category/category-detail/:id",
      name: "category-detail",
      meta: { authRequired: true },
      props:true,
      component: () => import("./views/user/category-detail"),
      beforeEnter:adminKontrol
    },
    {
      path: "/category/product-list/:id",
      name: "category-product-list",
      meta: { authRequired: true },
      props:true,
      component: () => import("./views/user/product-list"),
      beforeEnter:adminKontrol
    },
    {
      path: "/category/category-add",
      name: "category-add",
      meta: { authRequired: true },
      props:true,
      component: () => import("./views/user/category-add"),
      beforeEnter:adminKontrol
    },
    {
      path: "/product/product-add",
      name: "product-add",
      meta: { authRequired: true },
      component: () => import("./views/user/product-add"),
      beforeEnter:adminKontrol
    },
    {
      path: "/product/product-add-gallery/:id",
      name: "productAddGallery",
      meta: { authRequired: true },
      props:true,
      component: () => import("./views/user/product-add-gallery"),
      beforeEnter:adminKontrol
    },
    {
      path: "/category/category-add-gallery/:id",
      name: "categoryAddGallery",
      meta: { authRequired: true },
      props:true,
      component: () => import("./views/user/category-add-gallery"),
      beforeEnter:adminKontrol
    },
    {
      path: "/product/product-list/",
      name: "product-list",
      meta: { authRequired: true },
      component: () => import("./views/user/all-product-list"),
      beforeEnter:adminKontrol
    },
    {
      path: "/product/product-detail/:id",
      name: "product-detail",
      meta: { authRequired: true },
      props:true,
      component: () => import("./views/user/product-detail"),
      beforeEnter:adminKontrol
    },
    {
      path: "/user/email-verification",
      name: "email-verification",
      meta: { authRequired: true },
      props:true,
      component: () => import("./views/user/email-verification"),
      beforeEnter:adminKontrol
    },
    {
      path: "/user/add-brand-manager",
      name: "add-brand-manager",
      meta: { authRequired: true },
      props:true,
      component: () => import("./views/user/add-file-manager"),
      beforeEnter:adminKontrol
    }
  ],
  mode: 'hash',  
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

export default router
